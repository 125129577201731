<template>
  <div class="tal-dev">
    <div class="h">
      <h1 class="animate__animated animate__bounceInRight" align="center">
        TALENT DEVELOPMENT
      </h1>
      <h2 class="animate__animated animate__bounceInRight" align="center">
        人才发展
      </h2>
    </div>

    <div v-if="$route.path === '/index/talentDevelopment'">
      <a-row class="tal-text" type="flex" justify="end">
        <a-col :span="12">
          <img src="../../assets/image/63.png" />
        </a-col>
        <a-col :span="12" class="tal-text-describe">
          <p>
            达译聚焦于国产数据库领域人才培养的痛点、难点问题；人才发展中心聘用业界资深专家组建师资队伍，标准并持续优化金仓数据库的培训、认证体系，采用数据库理论和工作实操案例相结合教学方式，培养具有优秀国产数据库的研发、交付、运维专业技术型数据库人才，为国家信创事业提供有力的数据库人才保障。
          </p>
        </a-col>
      </a-row>

      <!-- 培训介绍 -->
      <h2 align="center" class="to-h">培训介绍</h2>

      <ul class="talent-list">
        <li v-for="item in list" :key="item.id">
          <div class="l">
            <img :src="item.img" alt="" />
          </div>
          <div class="r">
            <h3>{{ item.h3 }}</h3>
            <p>{{ item.p }}</p>
            <button @click="show(item)">查看详情</button>
          </div>
        </li>
      </ul>

      <a-pagination
        class="talent-pagination"
        v-model="current"
        :total="50"
        show-less-items
      />
    </div>
    <router-view></router-view>

    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      list: [
        {
          id: 1,
          img: require("../../assets/image/1024.png"),
          h3: "KCA培训",
          p: "本课程为您提供了一个独特的机会，是您能够再人大金仓的高级数据库管理上获得培训和认证。增加您的知识并提高您的技能熟练程度。了解如何更好的实现更高级的DBA职责，包括安全性、数据库调用和基准测试、监视、设置高可用性和复制等等。这个类涵盖了支持这些更深入的主题的概念和体系结构，以及可用于执行这些主题的选项。本文还介绍了如何更好地利用可用的工具来更有效的执行这些任务。",
          name: "talentDevDetails",
        },
        {
          id: 3,
          img: require("../../assets/image/1024.png"),
          h3: "KCA培训",
          p: "本课程为您提供了一个独特的机会，是您能够再人大金仓的高级数据库管理上获得培训和认证。增加您的知识并提高您的技能熟练程度。了解如何更好的实现更高级的DBA职责，包括安全性、数据库调用和基准测试、监视、设置高可用性和复制等等。这个类涵盖了支持这些更深入的主题的概念和体系结构，以及可用于执行这些主题的选项。本文还介绍了如何更好地利用可用的工具来更有效的执行这些任务。",
          name: "talentDevDetails",
        },
        {
          id: 4,
          img: require("../../assets/image/1024.png"),
          h3: "KCA培训",
          p: "本课程为您提供了一个独特的机会，是您能够再人大金仓的高级数据库管理上获得培训和认证。增加您的知识并提高您的技能熟练程度。了解如何更好的实现更高级的DBA职责，包括安全性、数据库调用和基准测试、监视、设置高可用性和复制等等。这个类涵盖了支持这些更深入的主题的概念和体系结构，以及可用于执行这些主题的选项。本文还介绍了如何更好地利用可用的工具来更有效的执行这些任务。",
          name: "talentDevDetails",
        },
        {
          id: 5,
          img: require("../../assets/image/1024.png"),
          h3: "KCA培训",
          p: "本课程为您提供了一个独特的机会，是您能够再人大金仓的高级数据库管理上获得培训和认证。增加您的知识并提高您的技能熟练程度。了解如何更好的实现更高级的DBA职责，包括安全性、数据库调用和基准测试、监视、设置高可用性和复制等等。这个类涵盖了支持这些更深入的主题的概念和体系结构，以及可用于执行这些主题的选项。本文还介绍了如何更好地利用可用的工具来更有效的执行这些任务。",
          name: "talentDevDetails",
        },
      ],
    };
  },
  methods: {
    show(item) {
      console.log(item);
      this.$router.push({ name: item.name });
    },
  },
  mounted() {
    // console.log(this.$route);
  },
};
</script>

<style lang="less">
.tal-dev {
  .to-h {
    margin-top: 60px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }
  position: relative;
  .h {
    width: 100%;
    position: absolute;
    top: -320px;
    h1 {
      color: #ffffff;
      font-size: 72px;
      font-family: Quantum;
      font-weight: 400;
    }
    h2 {
      color: #ffffff;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
  }
  .tal-text {
    width: 1440px;
    margin: 0 auto;
    margin-top: 60px;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
    .tal-text-describe {
      height: 50%;
      padding: 30px;
      color: #ffffff;
      margin-left: -60px;
      background: url("../../assets/image/776.png");
      border-radius: 15px;
      box-shadow: 0 10px 15px #cccccc;
      p {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }
    }
  }

  .talent-list {
    width: 1440px;
    margin: 0 auto;
    li {
      padding: 25px;
      display: flex;
      .l {
        img {
          width: 280px;
          height: 240px;
        }
      }
      .r {
        margin-left: 50px;
        h3 {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
        p {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
        button {
          width: 200px;
          height: 50px;
          border: 1px solid #ff6e29;
          border-radius: 6px;
          background: #ffffff;
          transition: 0.2s background ease;
          &:active {
            background: #ff6e29;
            color: #ffffff;
          }
        }
      }
    }
  }

  .talent-pagination {
    width: 1440px;
    margin: 50px auto;
  }
}
</style>
